const levelThree = [
  "Do you believe something has a calling? If so, do you think I've found mine?",
  "What would make you feel closer to me?",
  "If you could prescribe me one thing to do for the rest of this month, what would it be and why?",
  "What do you think I fear the most?",
  "When this game is over, what will you remember about me?",
  "When in this game did you feel most connected to me?",
  "If we were in a band, what would our name be?",
  "How would you describe me to a stranger?",
  "What do you recommend I let go of, if anything?",
  "How does one earn your vulnerability? Have I earned it? How can I earn more?",
  "What can I help you with?",
  "What about me most surprised you?",
  "In one word, describe how you feel right now?",
  "What question were you most afraid to answer?",
  "What do you think our most important similarity is?",
  "Based on what you learned about me, does my social media accurately reflect who I am? Why or why not?",
  "What would be the perfect gift for me?",
  "What has this conversation taught you about yourself?",
  "What parts of yourself do you see in me?",
  "What do I need to hear right now?",
  "How do our personalities complement each other?",
  "What can we create together?",
  "Based on what you know about me, do you have any Netflix recommendations?",
  "What do you admire most about me?",
  "What do you think I should know about myself that perhaps I'm unaware of?",
  "Why do you think we met?",
  "What do you think my superpower is?",
  "Based on what you learned about me, what book would you recommend I read?",
  "What do you think my weakness is?",
  "In one word, how would you describe our conversation?",
  "What about me is hardest for you to understand?",
  "What do you think my defining characteristic is?",
  "What answer of mine made you light up?",
  "What is a lesson you will take away from our conversation?",
  "What am I most qualified to give advice about?",
  "What answer of mine surprised you the most throughout the game, if any?",
  "What about us are you proudest of?",
  "Is there anything you still don't know about me that you wish you did?",
  "Has there been a moment the other made you jealous?",
];

export default levelThree;
