const levelTwo = [
  "Describe your ideal day.",
  "How often do you answer honestly when asked how you are doing?",
  "Have you recently changed your mind about anything important?",
  "How are you feeling at the moment, really?",
  "Has a stranger ever had a significant impact on your life?",
  "What is the best compliment a stranger has ever given you?",
  "What compliment do you wish you received more often?",
  "If you could give a title to the current chapter of your life, what would it be?",
  "Is there anything about yourself that you wouldn't want to change?",
  "What do you crave more of in your life?",
  "What would your younger self find unbelievable about your life today?",
  "What question are you currently seeking to answer in your life?",
  "What is your earliest memory of feeling happy?",
  "Complete the sentences: Strangers would describe me as ____. Only I know that I am ____.",
  "What is the most inexplicable event that has ever happened to you?",
  "What was the last thing you lied to your mother about?",
  "What are you still trying to prove to yourself?",
  "Are you more afraid of failure or success, and why?",
  "Have you ever told someone 'I love you' without meaning it? If so, why?",
  "What is your mother's name, and what is the most beautiful thing about her?",
  "Do you think the image you have of yourself matches the image others see of you?",
  "What is your father's name, and tell me one thing about him?",
  "Is there a feeling that you miss having?",
  "Are you lying to yourself about anything currently?",
  "If you could have it your way: who would you be with? Where would you be? And what would you be doing?",
  "Are you missing anyone right now? Do you think they are missing you too?",
  "What is your 1st love's name and the reason you feel in love with  him/her?",
  "What's been your happiest memory this past year?",
  "What is a dream you've let go of?",
  "What part of your life works? What part of your life hurts?",
  "What lesson took you the longest to unlearn?",
  "What was the last time you surprised yourself?",
  "What's the most pain you've ever been in that wasn't physical?",
  "How would you describe the feeling of being in love in one word?",
  "How can you become a better person?",
  "If you have, when was the moment you realized you weren't invincible?",
  "If you could get to know someone in your life on a deeper level, who would it be and why?",
  "What would you tell your younger version if you could go back in time?",
  "What makes you feel most fulfilled?",
  "What fear do you think holds me back the most?",
  "How does our age difference, or lack thereof, affect us? If at all?",
  "Have I helped you change your mind about anything?",
  "How have you seen me grow as a person while with you?",
  "When was the last time I hurt you, perhaps unintentionally?",
  "How do I show you love without telling you?",
  "What have I helped you appreciate about yourself?",
  "What recent experience made you feel closer to me?",
  "Are there any insecurities that you carried? If so, what are they?",
  "Finish the sentence: Thank you for accepting ____________.",
  "What do you wish we did or talked more of?",
  "What about you feels hardest to love?",
  "What's the best thing I've ever told you ?",
  "What feelings are hard for you to communicate to me? How can I make it easier?",
  "What have you been extra sensitive to lately?",
  "Do you think the things I do without realizing affects me positively or negatively? How does it affect us?",
  "What song best describes us?",
  "What are you currently working through that I don't see, if anything?",
  "What's the most attractive thing I do without realizing it?",
  "What's one small way I can be a better person?",
  "What has all this between us taught you about yourself?",
  "How does one earn your trust? Have I earned it? How can I earn more?",
  "The hardest thing for me to reveal about myself to you was _________.",
  "What would a day of completely spoiling me look like?",
];

export default levelTwo;
