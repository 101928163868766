import React, { useEffect, useState } from 'react';
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  Image,
  Link,
  Icon,
  SimpleGrid,
  Circle,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  Divider
} from '@chakra-ui/react';
import { 
  FaInstagram, 
  FaTwitter, 
  FaYoutube, 
  FaFacebook, 
  FaTiktok, 
  FaArrowRight, 
  FaHeart, 
  FaFire, 
  FaStar, 
  FaEnvelope 
} from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);

// Animated social button
const SocialButton = ({ children, label, href, color }) => {
  return (
    <MotionBox
      whileHover={{ 
        y: -5,
        boxShadow: '0 8px 15px -5px rgba(0,0,0,0.2)',
        scale: 1.1
      }}
      transition={{ duration: 0.3 }}
    >
      <chakra.button
        bg={color}
        rounded={'full'}
        w={12}
        h={12}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        color={'white'}
        shadow={'md'}
        _hover={{
          bg: useColorModeValue(`${color.split('.')[0]}.600`, `${color.split('.')[0]}.200`),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    </MotionBox>
  );
};

// Custom menu link component
const FooterLink = ({ children, href }) => {
  return (
    <Link 
      href={href || '#'} 
      fontSize="sm" 
      fontWeight="medium"
      color={useColorModeValue('gray.600', 'gray.400')}
      _hover={{ 
        color: useColorModeValue('orange.500', 'orange.300'),
        textDecoration: 'none'
      }}
      position="relative"
      display="inline-block"
      overflow="hidden"
      _after={{
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '0%',
        height: '1px',
        bg: useColorModeValue('orange.500', 'orange.300'),
        transition: 'width 0.3s ease'
      }}
      _groupHover={{
        _after: {
          width: '100%'
        }
      }}
      role="group"
    >
      {children}
    </Link>
  );
};

// Main component
export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    // Show elements when component is mounted
    setIsVisible(true);
  }, []);
  
  // Colors and styling
  const bgGradient = useColorModeValue(
    'linear(to-br, purple.900, black)',
    'linear(to-br, purple.900, black)'
  );
  
  const waveColor = useColorModeValue('white', 'gray.900');
  const textColor = "white";
  const accentColor = "orange.400";
  
  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };
  
  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Box position="relative">
      {/* Wave divider */}
      <Box
        position="relative"
        height="150px"
        width="100%"
        overflow="hidden"
        marginBottom="-5px"
      >
        <svg
          viewBox="0 0 1440 320"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0
          }}
        >
          <path
            d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,224C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            fill={waveColor}
          />
        </svg>
      </Box>
      
      {/* Banner section */}
      <Box
        bgGradient={bgGradient}
        color={textColor}
        position="relative"
        overflow="hidden"
        py={20}
      >
        {/* Animated background elements */}
        <Box position="absolute" top={0} left={0} right={0} bottom={0} overflow="hidden" pointerEvents="none">
          {[...Array(6)].map((_, i) => (
            <MotionBox
              key={i}
              position="absolute"
              width={`${100 + i * 50}px`}
              height={`${100 + i * 50}px`}
              borderRadius="full"
              bg={`rgba(251, 146, 60, 0.${i + 1})`}
              top={`${Math.random() * 80}%`}
              left={`${Math.random() * 80}%`}
              initial={{ opacity: 0.1 }}
              animate={{ 
                opacity: [0.1, 0.15, 0.1],
                scale: [1, 1.1, 1],
                rotate: 360
              }}
              transition={{
                duration: 15 + i * 5,
                repeat: Infinity,
                ease: "linear"
              }}
              filter="blur(60px)"
            />
          ))}
        </Box>
        
        <Container maxW={'7xl'} zIndex={2} position="relative">
          {/* Main CTA section */}
          <Flex 
            direction={{ base: 'column', md: 'row' }} 
            align="center" 
            justify="space-between"
            mb={20}
            pb={10}
            borderBottom="1px solid rgba(255,255,255,0.1)"
          >
            <MotionBox 
              maxW={{ base: 'full', md: '500px' }} 
              mb={{ base: 10, md: 0 }}
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Heading 
                fontSize={{ base: '3xl', md: '5xl' }}
                fontWeight="bold"
                mb={6}
                bgGradient="linear(to-r, orange.300, orange.400, pink.400)"
                bgClip="text"
              >
                Deepen Your Connections
              </Heading>
              
              <Text fontSize={{ base: 'md', md: 'lg' }} mb={8} lineHeight="tall">
                Join thousands of people who are transforming their relationships one question at a time. 
                Start meaningful conversations that create lasting bonds.
              </Text>
              
              <HStack spacing={4}>
                <Button 
                  size="lg" 
                  bg="orange.400" 
                  color="white" 
                  _hover={{ bg: 'orange.500', transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  rightIcon={<FaArrowRight />}
                  as={Link}
                  href="/start"
                  borderRadius="full"
                  px={8}
                  fontWeight="bold"
                  shadow="md"
                  transition="all 0.3s"
                >
                  Get Started
                </Button>
                
                <Button 
                  size="lg" 
                  variant="outline" 
                  color="white" 
                  _hover={{ bg: 'whiteAlpha.200', transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  borderColor="whiteAlpha.400"
                  borderRadius="full"
                  px={8}
                  transition="all 0.3s"
                >
                  Learn More
                </Button>
              </HStack>
            </MotionBox>
            
            {/* Decorative element */}
            <MotionBox
              initial={{ opacity: 0, scale: 0.8, rotate: -10 }}
              animate={isVisible ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0.8, rotate: -10 }}
              transition={{ duration: 0.7, delay: 0.3 }}
              position="relative"
            >
              <Circle 
                size={{ base: '200px', md: '300px' }}
                bgGradient="radial(orange.400, transparent 70%)"
                opacity={0.6}
                position="absolute"
                zIndex={0}
              />
              
              <Flex 
                width={{ base: '220px', md: '300px' }}
                height={{ base: '220px', md: '300px' }}
                bg="rgba(0,0,0,0.3)"
                backdropFilter="blur(10px)"
                borderRadius="20px"
                p={6}
                border="1px solid rgba(255,255,255,0.1)"
                boxShadow="0 10px 30px -5px rgba(0,0,0,0.3)"
                position="relative"
                zIndex={1}
                justify="center"
                align="center"
                direction="column"
                overflow="hidden"
              >
                <Icon as={FaFire} color="orange.400" fontSize="5xl" mb={4} />
                <Text fontWeight="bold" fontSize="xl" mb={2} textAlign="center">Ready to go deeper?</Text>
                <Text fontSize="sm" opacity={0.8} textAlign="center">
                  Unlock profound connections through conversation
                </Text>
                
                {/* Decorative corner elements */}
                {[0, 1, 2, 3].map(i => (
                  <Box
                    key={i}
                    position="absolute"
                    width="20px"
                    height="20px"
                    borderWidth="2px"
                    borderColor="orange.400"
                    top={i < 2 ? "10px" : "auto"}
                    bottom={i >= 2 ? "10px" : "auto"}
                    left={i % 2 === 0 ? "10px" : "auto"}
                    right={i % 2 === 1 ? "10px" : "auto"}
                    borderTop={i < 2 ? "2px" : "0"}
                    borderBottom={i >= 2 ? "2px" : "0"}
                    borderLeft={i % 2 === 0 ? "2px" : "0"}
                    borderRight={i % 2 === 1 ? "2px" : "0"}
                    borderTopLeftRadius={i === 0 ? "5px" : "0"}
                    borderTopRightRadius={i === 1 ? "5px" : "0"}
                    borderBottomLeftRadius={i === 2 ? "5px" : "0"}
                    borderBottomRightRadius={i === 3 ? "5px" : "0"}
                  />
                ))}
              </Flex>
            </MotionBox>
          </Flex>
          
          {/* Footer links and info */}
          <SimpleGrid 
            columns={{ base: 1, md: 2, lg: 4 }} 
            spacing={10}
            mb={16}
            as={motion.div}
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            variants={staggerChildren}
          >
            <VStack spacing={5} align="flex-start" as={motion.div} variants={itemVariant}>
              <Heading 
                as="h3" 
                fontSize="lg" 
                color="white"
                mb={2}
              >
                CoupleFriends
              </Heading>
              <Text fontSize="sm" color="gray.400" maxW="xs">
                Breaking down walls and building meaningful connections through thoughtful conversations.
              </Text>
              
              {/* Newsletter signup */}
              <Box width="full" mt={4}>
                <Text fontSize="sm" fontWeight="medium" mb={2}>Join our newsletter</Text>
                <FormControl>
                  <InputGroup size="md">
                    <Input 
                      placeholder="Your email" 
                      bg="whiteAlpha.100" 
                      border="none"
                      color="white"
                      _placeholder={{ color: 'gray.400' }}
                      _focus={{ bg: 'whiteAlpha.200', boxShadow: 'none' }}
                      borderRadius="full"
                    />
                    <InputRightElement>
                      <Button 
                        size="sm" 
                        borderRadius="full" 
                        bg="orange.400"
                        _hover={{ bg: 'orange.500' }}
                        mr={1}
                      >
                        <FaEnvelope />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
            </VStack>
            
            <VStack spacing={3} align="flex-start" as={motion.div} variants={itemVariant}>
              <Heading as="h3" fontSize="md" color="white" mb={2}>Quick Links</Heading>
              <FooterLink href="/">Home</FooterLink>
              <FooterLink href="/about">About</FooterLink>
              <FooterLink href="/start">Get Started</FooterLink>
              <FooterLink href="/contact">Contact</FooterLink>
              <FooterLink href="/couple">Couples</FooterLink>
            </VStack>
            
            <VStack spacing={3} align="flex-start" as={motion.div} variants={itemVariant}>
              <Heading as="h3" fontSize="md" color="white" mb={2}>Resources</Heading>
              <FooterLink href="#">Blog</FooterLink>
              <FooterLink href="#">FAQ</FooterLink>
              <FooterLink href="#">Testimonials</FooterLink>
              <FooterLink href="#">Conversation Tips</FooterLink>
              <FooterLink href="#">Relationship Guide</FooterLink>
            </VStack>
            
            <VStack spacing={3} align="flex-start" as={motion.div} variants={itemVariant}>
              <Heading as="h3" fontSize="md" color="white" mb={2}>Legal</Heading>
              <FooterLink href="#">Privacy Policy</FooterLink>
              <FooterLink href="#">Terms of Service</FooterLink>
              <FooterLink href="#">Cookie Policy</FooterLink>
              <FooterLink href="#">Data Protection</FooterLink>
            </VStack>
          </SimpleGrid>
          
          {/* Social and copyright */}
          <Flex 
            direction={{ base: 'column', md: 'row' }} 
            justify="space-between" 
            align={{ base: 'center', md: 'center' }}
            borderTop="1px solid rgba(255,255,255,0.1)"
            pt={8}
          >
            <Text fontSize="sm" color="gray.400" mb={{ base: 6, md: 0 }}>
              © 2025 CoupleFriends. All rights reserved
            </Text>
            
            <HStack spacing={4}>
              <SocialButton label={'Twitter'} href={'#'} color="twitter.500">
                <FaTwitter fontSize="18px" />
              </SocialButton>
              <SocialButton label={'Instagram'} href={'#'} color="pink.500">
                <FaInstagram fontSize="18px" />
              </SocialButton>
              <SocialButton label={'Facebook'} href={'#'} color="facebook.500">
                <FaFacebook fontSize="18px" />
              </SocialButton>
              <SocialButton label={'TikTok'} href={'#'} color="gray.500">
                <FaTiktok fontSize="18px" />
              </SocialButton>
              <SocialButton label={'YouTube'} href={'#'} color="red.500">
                <FaYoutube fontSize="18px" />
              </SocialButton>
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}