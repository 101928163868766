import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Flashcards from "./components/Flashcards";
import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Box,
  useDisclosure,
  Link,
  useMediaQuery,
  Flex,
  useColorModeValue,
  VStack,
  HStack,
  Icon,
  Badge,
  Circle,
  Divider
} from "@chakra-ui/react";
import { SlideFade, ScaleFade, Fade } from "@chakra-ui/react";
import { FaChevronRight, FaRocket, FaHeart, FaGlasses, FaBrain } from "react-icons/fa";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionText = motion(Text);

// Level card component
const LevelCard = ({ title, description, color, icon, onClick, isDisabled, number }) => {
  const bgColor = useColorModeValue(`${color}.50`, `${color}.900`);
  const borderColor = `${color}.400`;
  const hoverBg = `${color}.100`;
  const darkHoverBg = `${color}.800`;
  const textColor = useColorModeValue(`${color}.700`, `${color}.200`);
  
  return (
    <MotionBox
      p={6}
      bg={useColorModeValue(bgColor, `gray.800`)}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="xl"
      boxShadow="md"
      whileHover={{ 
        y: -5, 
        boxShadow: "0 10px 30px -10px rgba(0,0,0,0.2)",
      }}
      transition={{ duration: 0.3 }}
      onClick={onClick}
      cursor={isDisabled ? "default" : "pointer"}
      opacity={isDisabled ? 0.6 : 1}
      position="relative"
      overflow="hidden"
      width={{ base: "full", md: "230px" }}
    >
      <Circle
        size="40px"
        bg={`${color}.400`}
        color="white"
        position="absolute"
        top={3}
        right={3}
        fontSize="lg"
        fontWeight="bold"
      >
        {number}
      </Circle>
      
      <VStack spacing={4} align="start">
        <Circle size="50px" bg={`${color}.400`} color="white">
          <Icon as={icon} fontSize="xl" />
        </Circle>
        
        <Heading size="md" color={textColor}>
          {title}
        </Heading>
        
        <Text color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
          {description}
        </Text>
        
        <HStack>
          <Icon as={FaChevronRight} color={`${color}.500`} />
          <Text fontWeight="bold" color={`${color}.500`}>
            Begin Journey
          </Text>
        </HStack>
      </VStack>
      
      {/* Decorative elements */}
      <Box
        position="absolute"
        bottom="-20px"
        right="-20px"
        w="100px"
        h="100px"
        borderRadius="full"
        bg={`${color}.200`}
        opacity="0.3"
        zIndex="0"
      />
    </MotionBox>
  );
};

// Main component
export default function Start() {
  const { isOpen, onToggle } = useDisclosure();
  const [level, setLevel] = useState("");
  const [isReset, setIsReset] = useState(true);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const [animateTitle, setAnimateTitle] = useState(true);
  
  const bgGradient = useColorModeValue(
    "linear(to-br, gray.50, white, purple.50)",
    "linear(to-br, gray.900, purple.900)"
  );

  useEffect(() => {
    // Reset the animation when level changes
    if (!isReset) {
      setAnimateTitle(false);
      const timer = setTimeout(() => setAnimateTitle(true), 100);
      return () => clearTimeout(timer);
    }
  }, [level, isReset]);

  const handleLevelClick = (levelName) => {
    onToggle();
    setLevel(levelName);
    setIsReset(false);
  };

  const handleReset = () => {
    setIsReset(true);
    if (isOpen) onToggle();
  };

  // Animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };
  
  const staggerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <Box bgGradient={bgGradient} minH="100vh">
      <Navbar />
      
      <Container maxW={"7xl"} pt={{ base: 28, md: 36 }} pb={20}>
        <VStack spacing={{ base: 12, md: 16 }} align="center">
          <MotionBox
            initial="hidden"
            animate={animateTitle ? "visible" : "hidden"}
            variants={titleVariants}
            textAlign="center"
          >
            <Badge
              px={3}
              py={1}
              colorScheme="purple"
              fontSize="sm"
              borderRadius="full"
              mb={4}
            >
              Start Your Journey
            </Badge>
            
            <Heading
              fontWeight={800}
              fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
              lineHeight={"110%"}
              mb={6}
            >
              Pick your{" "}
              <Text
                as={"span"}
                bgGradient="linear(to-r, orange.400, pink.400)"
                bgClip="text"
              >
                level
              </Text>
            </Heading>
            
            <Text 
              color={useColorModeValue("gray.600", "gray.300")} 
              maxW={"3xl"}
              fontSize={{ base: "lg", md: "xl" }}
            >
              How deep do you want to go? Choose a level to begin your journey of connection.
            </Text>
          </MotionBox>

          {/* Level selection cards */}
          <MotionBox
            initial="hidden"
            animate="visible"
            variants={staggerVariants}
            width="full"
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              gap={6}
              justify="center"
              align="stretch"
              wrap="wrap"
            >
              <MotionBox variants={itemVariants}>
                <LevelCard
                  title="Discovery"
                  description="Begin your journey with casual questions to break the ice and discover basic preferences."
                  color="green"
                  icon={FaRocket}
                  onClick={() => handleLevelClick("Level One")}
                  isDisabled={!isReset}
                  number="1"
                />
              </MotionBox>
              
              <MotionBox variants={itemVariants}>
                <LevelCard
                  title="Empathy"
                  description="Deepen your connection with questions that explore emotions and personal experiences."
                  color="blue"
                  icon={FaHeart}
                  onClick={() => handleLevelClick("Level Two")}
                  isDisabled={!isReset}
                  number="2"
                />
              </MotionBox>
              
              <MotionBox variants={itemVariants}>
                <LevelCard
                  title="Clarity"
                  description="Challenge each other with profound questions about values, beliefs and aspirations."
                  color="red"
                  icon={FaBrain}
                  onClick={() => handleLevelClick("Level Three")}
                  isDisabled={!isReset}
                  number="3"
                />
              </MotionBox>
            </Flex>
          </MotionBox>
          
          {/* Reset button */}
          {!isReset && (
            <ScaleFade initialScale={0.8} in={!isReset}>
              <Button
                rounded={"full"}
                size="lg"
                px={8}
                fontWeight="bold"
                variant="outline"
                colorScheme="purple"
                leftIcon={<Icon as={FaGlasses} />}
                onClick={handleReset}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "md"
                }}
                transition="all 0.3s"
              >
                Start Over
              </Button>
            </ScaleFade>
          )}

          {/* Flashcards */}
          <Box w="full" maxW="5xl">
            {level && (
              <Box mb={8}>
                <Divider mb={8} />
                <Flex align="center" justify="space-between" mb={6}>
                  <Heading size="lg">
                    <Text as="span" color={
                      level === "Level One" ? "green.500" : 
                      level === "Level Two" ? "blue.500" : "red.500"
                    }>
                      {level}
                    </Text>{" "}
                    Questions
                  </Heading>
                  <Badge 
                    colorScheme={
                      level === "Level One" ? "green" : 
                      level === "Level Two" ? "blue" : "red"
                    }
                    fontSize="md"
                    px={3}
                    py={1}
                    borderRadius="full"
                  >
                    Active
                  </Badge>
                </Flex>
              </Box>
            )}
            
            <SlideFade in={isOpen} offsetY="40px">
              <Box 
                borderRadius="xl" 
                overflow="hidden"
                boxShadow="xl"
                bg={useColorModeValue("white", "gray.800")}
              >
                <Flashcards level={level} />
              </Box>
            </SlideFade>
          </Box>
        </VStack>
      </Container>
      
      <Footer />
    </Box>
  );
}