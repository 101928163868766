const levelOne = [
  "Do you think I am more of a morning person or a night owl? Why?",
  "What do you think my shoes reveal about my personality or style?",
  "How would you describe my clothing style based on what you see?",
  "Do you think I have ever been fired from a job, and if so, what do you think was the reason?",
  "Do I come across as a kind person to you? Why or why not?",
  "Do I seem mean or unfriendly to you? Why or why not?",
  "Who do you think I am most likely to have a celebrity crush on?",
  "Do you think I am more of a creative or analytical type? Why?",
  "Do you think I intimidate others? Why or why not?",
  "What fast food restaurant do you think I would most likely visit, and what would I order there?",
  "Do you think I have ever checked an ex's phone for evidence of wrongdoing?",
  "Do you think I fall in love easily, or do I take more time to develop feelings? Why?",
  "What was the first thing you noticed about me?",
  "How many speeding tickets do you think I have received in my lifetime?",
  "Do you think I enjoy camping, and if so, how high maintenance is my setup?",
  "What do you think my body language is communicating at the moment?",
  "When I was a child, what do you think I wanted to be when I grew up?",
  "Do you think I prefer coffee or tea, and if tea, do I prefer it sweetened or unsweetened?",
  "Do you think I am the type of person who would get a tattoo with a name on it? Why or why not?",
  "What was your initial impression of me?",
  "What do you think my Instagram account reveals about my personality or interests?",
  "Do you think I am usually early, on time, or late to events? Why?",
  "What do you think I am most likely to splurge on or treat myself to?",
  "What do you think is the most challenging aspect of my job or career?",
  "What about me seems strange or unfamiliar to you?",
  "Do you think plants thrive or die under my care? Why?",
  "What do you think my go-to karaoke song would be?",
  "If you had to guess based on my appearance alone, what would you say about me?",
  "What reality TV show do you think I am most likely to binge watch? Why?",
  "What about me interests you or makes you curious?",
  "On a scale of 1-10, how messy do you think my car is? Explain your rating.",
  "What subject(s) do you think I excelled in at school, and did I struggle with any particular subjects?",
  "If you were to buy me a gift without knowing anything about me other than my appearance, what would you choose?",
  "If I were to play a character in a movie, what type of character do you think I would portray?",
  "Do you think I am more of a cat person or a dog person? Why?",
  "If MySpace were still a popular social media platform, what song would you think I would use as my profile song?",
  "Do you think I was popular in school, or was I more of an outsider? Why?",
  "What compliment do you think I hear the most?",
  "What does my phone wallpaper tell you about me?",
  "Do I remind you of anyone?",
  "What's your favorite way to relax?",
  "What makes you laugh the most?",
  "What is your biggest pet peeve?",
  "What is something that people are obsessed with but you just don’t get the point of?",
  "What’s something you like to do the old-fashioned way?",
  "When people come to you for help, what do they usually want help with?",
  "What would you do if you won the lottery?",
  "What gets you fired up?",
  "What’s one responsibility you really wish you didn’t have?",
  "Do you believe in second chances?",
  "Who did you share a table with at lunch in high school?",
  "On a scale of 1-10, how open do you feel I am with you? Explain.",
  "What assumption did you make about me that turned out to be false?",
  "What was the first thing you noticed about me?",
  "What do you think my perfect date night would be?",
  "Who do you think was more nervous seeing one another? Explain.",
  "How important do you think birthdays and holidays are to me? Explain.",
];

export default levelOne;
