import React from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Hero from './components/Hero'

export default function Home() {
  return (
    <div>
      <Navbar/>
      <div style={{height:'100vh'}}>
      <Hero/>
      </div>
      <Footer/>
    </div>
  )
}
