// src/components/Navbar.js

import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Text,
  useMediaQuery,
  Container,
  Divider,
  Collapse,
  VStack,
  Badge
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  AddIcon,
  SunIcon,
  MoonIcon,
  ChevronDownIcon
} from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { FaFire, FaHeart } from "react-icons/fa";
import Logo from "../assets/logo.svg";
import "../css/App.css";
import { useLocation } from "react-router-dom";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  // Check if we're on the home page (main landing)
  const isHomePage = location.pathname === "/";

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Determine background color based on page and scroll
  const bgColor = useColorModeValue(
    // For light mode
    isHomePage && !scrolled 
      ? "transparent" 
      : "rgba(255, 255, 255, 0.9)",
    // For dark mode  
    isHomePage && !scrolled 
      ? "transparent" 
      : "rgba(26, 32, 44, 0.9)"
  );

  // Determine text color based on page and scroll
  const textColor = useColorModeValue(
    // For light mode
    (isHomePage && !scrolled) ? "white" : "gray.800",
    // For dark mode
    "white"
  );
  
  // Mobile menu background with improved contrast on all pages
  const mobileBgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.98)", 
    "rgba(26, 32, 44, 0.98)"
  );
  
  const mobileHoverBgColor = useColorModeValue(
    "orange.50",
    "gray.700"
  );

  // Hamburger icon with proper contrast
  const hamburgerColor = useColorModeValue(
    // For light mode - dark color on light backgrounds, light color on transparent home
    (isHomePage && !scrolled) ? "white" : "gray.800",
    // For dark mode - always light
    "white"
  );

  // Hamburger hover background for better visibility
  const hamburgerHoverBg = useColorModeValue(
    // For light mode
    (isHomePage && !scrolled) ? "whiteAlpha.200" : "blackAlpha.50",
    // For dark mode
    "whiteAlpha.200"
  );

  // Add a subtle shadow for non-home pages to enhance visibility
  const boxShadow = (!isHomePage || scrolled) 
    ? "0 4px 20px rgba(0, 0, 0, 0.1)" 
    : "none";

  // Always use backdrop filter for non-home pages to improve contrast
  const backdropFilter = (!isHomePage || scrolled) 
    ? "blur(10px)" 
    : "none";

  const navItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: (i) => ({ 
      opacity: 1, 
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5
      }
    })
  };

  const logoVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  // Mobile text color that ensures visibility on all backgrounds
  const mobileTextColor = useColorModeValue("gray.800", "white");

  return (
    <Box 
      position="fixed" 
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      transition="all 0.3s ease"
      backdropFilter={backdropFilter}
      boxShadow={boxShadow}
      bg={bgColor}
    >
      <Container maxW="7xl" px={{ base: 4, md: 6 }}>
        <Flex h={20} alignItems="center" justifyContent="space-between">
          <MotionFlex
            initial="hidden"
            animate="visible"
            variants={logoVariants}
            alignItems="center"
          >
            <IconButton
              size="md"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label="Open Menu"
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
              variant="ghost"
              color={hamburgerColor}
              _hover={{ bg: hamburgerHoverBg }}
              bg={!isHomePage || scrolled ? "whiteAlpha.200" : "transparent"}
            />
            <HStack spacing={2} alignItems="center">
              <MotionBox
                animate={{ 
                  rotate: [0, 10, -10, 10, 0],
                  scale: [1, 1.1, 1]
                }}
                transition={{ 
                  duration: 1.5,
                  repeat: Infinity,
                  repeatDelay: 4
                }}
                display={{ base: "none", md: "block" }}
                color="orange.400"
                mr={2}
                fontSize="xl"
              >
                <FaFire />
              </MotionBox>
              <Link href="/" _hover={{ textDecoration: "none" }}>
                <Flex align="center">
                  <MotionText
                    color="orange.400"
                    fontWeight="800"
                    fontSize={isSmallScreen ? "xl" : "2xl"}
                    letterSpacing="tight"
                    style={{
                      fontFamily: "Sen, sans-serif",
                    }}
                  >
                    Couple
                  </MotionText>
                  <MotionText
                    color={textColor}
                    fontWeight="800"
                    fontSize={isSmallScreen ? "xl" : "2xl"}
                    letterSpacing="tight"
                    style={{
                      fontFamily: "Sen, sans-serif",
                    }}
                  >
                    Friends
                  </MotionText>
                </Flex>
              </Link>
            </HStack>
          </MotionFlex>

          <HStack
            as="nav"
            spacing={8}
            display={{ base: "none", md: "flex" }}
          >
            {["About", "Contact", "Couples"].map((item, i) => (
              <MotionBox
                key={item}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={navItemVariants}
              >
                <Link
                  href={`/${item.toLowerCase()}`}
                  px={4}
                  py={2}
                  rounded="full"
                  color={textColor}
                  fontWeight="medium"
                  position="relative"
                  _hover={{ textDecoration: "none" }}
                >
                  <Box
                    position="absolute"
                    bottom="-2px"
                    left="50%"
                    height="2px"
                    bg="orange.400"
                    width="0%"
                    transform="translateX(-50%)"
                    transition="all 0.3s ease"
                    _groupHover={{ width: "80%" }}
                  />
                  <Text _groupHover={{ color: "orange.400" }} transition="all 0.3s ease">
                    {item}
                  </Text>
                </Link>
              </MotionBox>
            ))}
          </HStack>

          <MotionFlex
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            alignItems="center"
          >
            <Button
              onClick={toggleColorMode}
              mr={4}
              rounded="full"
              size="md"
              variant="ghost"
              color={textColor}
              _hover={{ bg: hamburgerHoverBg }}
            >
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
            <Button
              as={Link}
              href="/start"
              variant="solid"
              bg="orange.400"
              color="white"
              size="md"
              px={6}
              rounded="full"
              fontWeight="bold"
              leftIcon={<FaHeart />}
              _hover={{ 
                transform: "translateY(-2px)",
                boxShadow: "0 4px 12px rgba(251, 146, 60, 0.4)",
                bg: "orange.500"
              }}
              transition="all 0.3s ease"
            >
              Start Now
            </Button>
          </MotionFlex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Box
            bg={mobileBgColor}
            backdropFilter="blur(10px)"
            borderRadius="xl"
            mt={4}
            p={4}
            boxShadow="xl"
            borderWidth="1px"
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <VStack spacing={4} align="stretch" divider={<Divider />}>
              {["About", "Contact", "Couples"].map((item) => (
                <Link
                  key={item}
                  href={`/${item.toLowerCase()}`}
                  px={3}
                  py={3}
                  rounded="md"
                  fontWeight="medium"
                  color={mobileTextColor}
                  _hover={{
                    bg: mobileHoverBgColor,
                    color: "orange.400",
                  }}
                >
                  {item}
                </Link>
              ))}
              <Button
                as={Link}
                href="/start"
                variant="solid"
                bg="orange.400"
                color="white"
                size="md"
                width="full"
                mt={2}
                fontWeight="bold"
                leftIcon={<FaHeart />}
                _hover={{ bg: "orange.500" }}
              >
                Start Now
              </Button>
            </VStack>
          </Box>
        </Collapse>
      </Container>
    </Box>
  );
}