import React, { useState, useEffect, useRef } from "react";
import { 
  Box, 
  Stack, 
  IconButton, 
  useMediaQuery, 
  Flex, 
  Text, 
  Button,
  Badge,
  useColorModeValue,
  Heading,
  Progress,
  HStack,
  Circle,
  VStack,
  useToast,
  Tooltip,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Avatar,
  AvatarGroup
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import Card from "./Card";
import { 
  FaAngleRight, 
  FaAngleLeft, 
  FaRandom, 
  FaShare, 
  FaHeart, 
  FaBookmark, 
  FaPalette,
  FaRedo,
  FaGraduationCap,
  FaLightbulb
} from "react-icons/fa";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

// Import data
import levelOne from "../data/LevelOne";
import levelTwo from "../data/LevelTwo";
import levelThree from "../data/LevelThree";
import CouplesOne from "../data/CouplesOne";
import CouplesTwo from "../data/CouplesTwo";
import CouplesThree from "../data/CouplesThree";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);

// Custom card component to replace the imported Card
const EnhancedCard = ({ question, level, isActive, isSaved, onSave, onNext, onPrev }) => {
  const colorMap = {
    "Level One": "green",
    "Level Two": "blue",
    "Level Three": "red",
    "Couples One": "pink",
    "Couples Two": "purple",
    "Couples Three": "orange",
    "Everything": "teal"
  };
  
  const cardBg = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue("gray.800", "white");
  const accentColor = `${colorMap[level] || "blue"}.400`;
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const bgGradient = useColorModeValue(
    `linear(to-br, ${colorMap[level] || "blue"}.50, white, ${colorMap[level] || "blue"}.50)`,
    `linear(to-br, gray.800, ${colorMap[level] || "blue"}.900)`
  );
  
  return (
    <MotionBox 
      w={{ base: "100%", md: "500px" }}
      h={{ base: "auto", md: "300px" }}
      bgGradient={bgGradient}
      borderRadius="2xl"
      boxShadow="xl"
      position="relative"
      overflow="hidden"
      p={{ base: 4, md: 6 }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ 
        opacity: isActive ? 1 : 0,
        y: isActive ? 0 : 50,
        rotateY: isActive ? 0 : 90
      }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      }}
    >
      {/* Pattern overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bgImage="url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj48cGF0aCBkPSJNMzAgMjQuMDA3TDYwIDB2NjBIMHYtNjBsMzAgMjQuMDA3eiIgZmlsbD0icmdiYSgyNTUsMjU1LDI1NSwwLjAzKSIvPjwvc3ZnPg==')"
        opacity="0.6"
        pointerEvents="none"
      />
      
      {/* Radial gradient */}
      <Box
        position="absolute"
        width="300px"
        height="300px"
        borderRadius="full"
        background={`radial-gradient(circle, ${accentColor} 0%, transparent 70%)`}
        top="-150px"
        right="-150px"
        opacity="0.15"
        pointerEvents="none"
      />
      
      {/* Level badge */}
      <Badge
        position="absolute"
        top={4}
        left={4}
        colorScheme={colorMap[level] || "blue"}
        borderRadius="full"
        px={3}
        py={1}
        fontSize="xs"
        fontWeight="bold"
        textTransform="uppercase"
      >
        {level}
      </Badge>
      
      {/* Save button */}
      <IconButton
        position="absolute"
        top={4}
        right={4}
        size="sm"
        aria-label="Save question"
        variant="ghost"
        icon={<FaBookmark />}
        color={isSaved ? accentColor : "gray.400"}
        onClick={onSave}
      />
      
      {/* Question content */}
      <VStack 
        spacing={6} 
        h="full" 
        justifyContent="center" 
        zIndex={1} 
        position="relative"
        pt={10}
      >
        <MotionText
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight="bold"
          textAlign="center"
          color={cardColor}
          mb={4}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {question}
        </MotionText>
        
        {/* Tip button */}
        <Tooltip label="View conversation starter tips">
          <Button 
            size="sm" 
            leftIcon={<FaLightbulb />} 
            onClick={onOpen} 
            variant="ghost"
            colorScheme={colorMap[level] || "blue"}
          >
            Conversation Tips
          </Button>
        </Tooltip>
      </VStack>
      
      {/* Tips modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent borderRadius="xl" p={4}>
          <ModalHeader>How to use this question</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack align="start" spacing={4}>
              <Box>
                <Heading size="sm" mb={2}>Starter Tips</Heading>
                <Text fontSize="sm">
                  • Take turns answering the question
                  <br />
                  • Listen actively without interrupting
                  <br />
                  • Ask follow-up questions to deepen the conversation
                  <br />
                  • Share your own perspective after they answer
                </Text>
              </Box>
              <Divider />
              <Box>
                <Heading size="sm" mb={2}>Follow-up Questions</Heading>
                <Text fontSize="sm">
                  • "What made you think of that answer?"
                  <br />
                  • "How has that changed over time for you?"
                  <br />
                  • "Can you tell me more about why you feel that way?"
                </Text>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </MotionBox>
  );
};

export default function Flashcards(props) {
  const { level } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [seenQuestions, setSeenQuestions] = useState([]);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const [direction, setDirection] = useState("next");
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [activeCardKey, setActiveCardKey] = useState(Date.now());
  const [cardTheme, setCardTheme] = useState("default");
  const { width, height } = useWindowSize();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  // Get questions based on level
  const allQuestions = [];
  if (level === "Level One") {
    allQuestions.push(...levelOne);
  } else if (level === "Level Two") {
    allQuestions.push(...levelTwo);
  } else if (level === "Level Three") {
    allQuestions.push(...levelThree);
  } else if (level === "Everything") {
    allQuestions.push(...levelOne, ...levelTwo, ...levelThree);
  } else if (level === "Couples One") {
    allQuestions.push(...CouplesOne);
  } else if (level === "Couples Two") {
    allQuestions.push(...CouplesTwo);
  } else if (level === "Couples Three") {
    allQuestions.push(...CouplesThree);
  }
  
  // Color mappings
  const colorMap = {
    "Level One": "green",
    "Level Two": "blue",
    "Level Three": "red",
    "Couples One": "pink",
    "Couples Two": "purple",
    "Couples Three": "orange",
    "Everything": "teal"
  };
  
  const currentColor = colorMap[level] || "green";
  
  // Handlers
  const handleNextQuestion = () => {
    if (currentQuestion === allQuestions.length - 1) {
      // Show completion toast
      toast({
        title: "You've reached the end!",
        description: "You've seen all questions in this level.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return;
    } else {
      setDirection("next");
      setActiveCardKey(Date.now()); // Force re-render with new key
      setCurrentQuestion((currentQuestion + 1) % allQuestions.length);
      setSeenQuestions([...seenQuestions, allQuestions[currentQuestion]]);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion === 0) {
      toast({
        title: "First Question",
        description: "You're at the first question in this level.",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      setDirection("prev");
      setActiveCardKey(Date.now()); // Force re-render with new key
      setCurrentQuestion(
        (currentQuestion - 1 + allQuestions.length) % allQuestions.length
      );
    }
  };
  
  const handleRandomQuestion = () => {
    const randomIndex = Math.floor(Math.random() * allQuestions.length);
    setCurrentQuestion(randomIndex);
    setActiveCardKey(Date.now());
    
    // Show confetti
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 2000);
  };
  
  const handleSaveQuestion = () => {
    const currentQuestionText = allQuestions[currentQuestion];
    
    // Toggle saved status
    if (savedQuestions.includes(currentQuestionText)) {
      setSavedQuestions(savedQuestions.filter(q => q !== currentQuestionText));
      toast({
        title: "Question removed from favorites",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    } else {
      setSavedQuestions([...savedQuestions, currentQuestionText]);
      toast({
        title: "Question saved to favorites!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  
  const handleShareQuestion = () => {
    // Mock sharing functionality
    toast({
      title: "Question shared!",
      description: "Link copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  
  // Progress calculation
  const progressPercentage = (currentQuestion + 1) / allQuestions.length * 100;
  
  return (
    <Box 
      borderRadius="xl" 
      p={{ base: 4, md: 8 }}
      bg={useColorModeValue("gray.50", "gray.900")}
      position="relative"
      overflow="hidden"
    >
      {/* Confetti animation */}
      {showConfetti && <Confetti width={width} height={height} recycle={false} numberOfPieces={200} />}
      
      {/* Top section with progress */}
      <HStack justify="space-between" mb={6}>
        <VStack align="start" spacing={1}>
          <HStack>
            <Text fontWeight="bold" fontSize="sm">Question</Text>
            <Badge colorScheme={currentColor}>
              {currentQuestion + 1} of {allQuestions.length}
            </Badge>
          </HStack>
          <Progress 
            value={progressPercentage} 
            size="sm" 
            colorScheme={currentColor}
            borderRadius="full" 
            width={{ base: "150px", md: "200px" }}
          />
        </VStack>
        
        <HStack>
          <Tooltip label="Random Question">
            <IconButton
              variant="outline"
              colorScheme={currentColor}
              aria-label="Random Question"
              icon={<FaRandom />}
              rounded="full"
              onClick={handleRandomQuestion}
              size={{ base: "sm", md: "md" }}
            />
          </Tooltip>
          
          <Tooltip label="Share Question">
            <IconButton
              variant="outline"
              colorScheme={currentColor}
              aria-label="Share Question"
              icon={<FaShare />}
              rounded="full"
              onClick={handleShareQuestion}
              size={{ base: "sm", md: "md" }}
            />
          </Tooltip>
          
          <AvatarGroup size="sm" max={3}>
            <Avatar name="User 1" bg={`${currentColor}.500`} />
            <Avatar name="User 2" bg={`${currentColor}.400`} />
            <Avatar name="User 3" bg={`${currentColor}.300`} />
          </AvatarGroup>
        </HStack>
      </HStack>
      
      {/* Main flashcard section */}
      {isSmallScreen ? (
        <Box>
          <AnimatePresence mode="wait">
            <EnhancedCard 
              key={activeCardKey}
              question={allQuestions[currentQuestion]}
              level={level}
              isActive={true}
              isSaved={savedQuestions.includes(allQuestions[currentQuestion])}
              onSave={handleSaveQuestion}
              onNext={handleNextQuestion}
              onPrev={handlePrevQuestion}
            />
          </AnimatePresence>
          
<HStack justify="center" mt={6} spacing={4}>
  <Circle 
    size={{ base: "60px", md: "70px" }}
    bg={`${currentColor}.400`}
    color="white"
    boxShadow="lg"
    transition="all 0.3s"
    _hover={{ transform: "scale(1.1)" }}
    opacity={currentQuestion === 0 ? 0.6 : 1}
  >
    <IconButton
      variant="unstyled"
      aria-label="Previous Question"
      icon={<FaAngleLeft size={30} />}
      left="6px"
      onClick={handlePrevQuestion}
      color="white"
      size="lg"
      isDisabled={currentQuestion === 0}
    />
  </Circle>
  
  <Circle 
    size={{ base: "60px", md: "70px" }}
    bg={`${currentColor}.400`}
    color="white"
    boxShadow="lg"
    transition="all 0.3s"
    _hover={{ transform: "scale(1.1)" }}
    opacity={currentQuestion === allQuestions.length - 1 ? 0.6 : 1}
  >
    <IconButton
      variant="unstyled"
      aria-label="Next Question"
      icon={<FaAngleRight size={30} />}
      onClick={handleNextQuestion}
      left='10px'
      color="white"
      size="lg"
      isDisabled={currentQuestion === allQuestions.length - 1}
    />
  </Circle>
</HStack>

        </Box>
      ) : (
<Flex justify="space-between" align="center" w="100%">
  <Box w="60px" textAlign="center">
    <IconButton
      variant="solid"
      colorScheme={currentColor}
      aria-label="Previous Question"
      icon={<FaAngleLeft size={24} />}
      rounded="full"
      onClick={handlePrevQuestion}
      size="lg"
      boxShadow="md"
      transform="translateX(0)"
      _hover={{ transform: "translateX(-5px)" }}
      transition="all 0.3s"
      isDisabled={currentQuestion === 0}
    />
  </Box>
  
  <AnimatePresence mode="wait">
    <EnhancedCard 
      key={activeCardKey}
      question={allQuestions[currentQuestion]}
      level={level}
      isActive={true}
      isSaved={savedQuestions.includes(allQuestions[currentQuestion])}
      onSave={handleSaveQuestion}
      onNext={handleNextQuestion}
      onPrev={handlePrevQuestion}
    />
  </AnimatePresence>
  
  <Box w="60px" textAlign="center">
    <IconButton
      variant="solid"
      colorScheme={currentColor}
      aria-label="Next Question"
      icon={<FaAngleRight size={24} />}
      rounded="full"
      onClick={handleNextQuestion}
      size="lg"
      boxShadow="md"
      transform="translateX(0)"
      _hover={{ transform: "translateX(5px)" }}
      transition="all 0.3s"
      isDisabled={currentQuestion === allQuestions.length - 1}
    />
  </Box>
</Flex>
      )}
      
      {/* Bottom section with additional actions */}
      <Box mt={8}>
        <Divider mb={4} />
        <HStack justify="space-between">
          <Text fontSize="sm" color="gray.500">
            Saved: {savedQuestions.length} questions
          </Text>
          
          <HStack>
            <Tooltip label="Change Card Theme">
              <IconButton
                variant="ghost"
                aria-label="Card Theme"
                icon={<FaPalette />}
                size="sm"
                onClick={onOpen}
              />
            </Tooltip>
            
            <Tooltip label="Reset Progress">
              <IconButton
                variant="ghost"
                aria-label="Reset Progress"
                icon={<FaRedo />}
                size="sm"
                onClick={() => {
                  setCurrentQuestion(0);
                  setSeenQuestions([]);
                  setActiveCardKey(Date.now());
                  toast({
                    title: "Progress reset",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              />
            </Tooltip>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
}