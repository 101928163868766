import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Flashcards from "./components/Flashcards";
import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Box,
  useDisclosure,
  useMediaQuery,
  useColorModeValue,
  Flex,
  VStack,
  HStack,
  Icon,
  Circle,
  Badge,
  Divider,
  ScaleFade,
  SlideFade
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaHeart, FaCompass, FaAnchor, FaArrowRight, FaRedo } from "react-icons/fa";

const MotionBox = motion(Box);
const MotionText = motion(Text);

// Level card component with beautiful styling
const LevelCard = ({ number, title, description, color, icon, onClick, isDisabled }) => {
  // All color values calculated upfront
  const cardBg = useColorModeValue(`${color}.50`, `${color}.900`);
  const cardBorder = `${color}.400`;
  const textColor = useColorModeValue(`${color}.700`, `${color}.200`);

  return (
    <MotionBox
      p={6}
      bg={useColorModeValue(cardBg, `gray.800`)}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="xl"
      boxShadow="md"
      whileHover={{ 
        y: -5, 
        boxShadow: "0 10px 30px -10px rgba(0,0,0,0.2)",
      }}
      transition={{ duration: 0.3 }}
      onClick={onClick}
      cursor={isDisabled ? "default" : "pointer"}
      opacity={isDisabled ? 0.6 : 1}
      position="relative"
      overflow="hidden"
      width={{ base: "full", md: "230px" }}
    >
      <Circle
        size="40px"
        bg={`${color}.400`}
        color="white"
        position="absolute"
        top={3}
        right={3}
        fontSize="lg"
        fontWeight="bold"
      >
        {number}
      </Circle>
      
      <VStack spacing={4} align="start">
        <Circle size="50px" bg={`${color}.400`} color="white">
          <Icon as={icon} fontSize="xl" />
        </Circle>
        
        <Heading size="md" color={textColor}>
          {title}
        </Heading>
        
        <Text color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
          {description}
        </Text>
        
        <HStack>
          <Icon as={FaArrowRight} color={`${color}.500`} />
          <Text fontWeight="bold" color={`${color}.500`}>
            Start Journey
          </Text>
        </HStack>
      </VStack>
      
      {/* Decorative element */}
      <Box
        position="absolute"
        bottom="-20px"
        right="-20px"
        w="100px"
        h="100px"
        borderRadius="full"
        bg={`${color}.200`}
        opacity="0.3"
        zIndex="0"
      />
    </MotionBox>
  );
};

export default function CouplesStart() {
  // All hooks at the top
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const { isOpen, onToggle } = useDisclosure();
  const [level, setLevel] = useState("");
  const [isReset, setIsReset] = useState(true);
  const [animateTitle, setAnimateTitle] = useState(true);
  
  // Get all color mode values upfront
  const bgGradient = useColorModeValue(
    "linear(to-br, pink.50, white, purple.50)",
    "linear(to-br, gray.900, purple.900)"
  );
  
  // Animation variants defined outside of render logic
  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };
  
  const staggerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  // Reset animation when level changes
  useEffect(() => {
    if (!isReset) {
      setAnimateTitle(false);
      const timer = setTimeout(() => setAnimateTitle(true), 100);
      return () => clearTimeout(timer);
    }
  }, [level, isReset]);

  // Level handlers
  const handleLevelOneClick = () => {
    onToggle();
    setLevel("Couples One");
    setIsReset(false);
  };

  const handleLevelTwoClick = () => {
    onToggle();
    setLevel("Couples Two");
    setIsReset(false);
  };

  const handleLevelThreeClick = () => {
    onToggle();
    setLevel("Couples Three");
    setIsReset(false);
  };

  const handleReset = () => {
    setIsReset(true);
    if (isOpen) onToggle();
  };

  return (
    <Box bgGradient={bgGradient} minH="100vh">
      <Navbar />
      
      <Container maxW={"7xl"} pt={{ base: 28, md: 36 }} pb={20}>
        <VStack spacing={{ base: 12, md: 16 }} align="center">
          {/* Animated title section */}
          <MotionBox
            initial="hidden"
            animate={animateTitle ? "visible" : "hidden"}
            variants={titleVariants}
            textAlign="center"
          >
            <Badge
              px={3}
              py={1}
              colorScheme="pink"
              fontSize="sm"
              borderRadius="full"
              mb={4}
            >
              Strengthen Your Bond
            </Badge>
            
            <Heading
              fontWeight={800}
              fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
              lineHeight={"110%"}
              mb={6}
            >
              <Text
                as={"span"}
                bgGradient="linear(to-r, pink.400, purple.500)"
                bgClip="text"
              >
                Couples:
              </Text>{" "}
              Pick your{" "}
              <Text
                as={"span"}
                bgGradient="linear(to-r, pink.400, purple.500)"
                bgClip="text"
              >
                level
              </Text>
            </Heading>
            
            <Text 
              color={useColorModeValue("gray.600", "gray.300")} 
              maxW={"3xl"}
              fontSize={{ base: "lg", md: "xl" }}
            >
              Deepen your connection with honest conversations. Choose your comfort level and grow together.
            </Text>
            
            <Box
              as="div"
              display="inline-block"
              position="relative"
              mt={4}
              _after={{
                content: '""',
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "pink.100",
                zIndex: -1,
              }}
            >
              <Text color="pink.500" fontWeight="semibold">
                Warning: honest answers may surface.
              </Text>
            </Box>
          </MotionBox>

          {/* Level selection cards */}
          <MotionBox
            initial="hidden"
            animate="visible"
            variants={staggerVariants}
            width="full"
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              gap={6}
              justify="center"
              align="stretch"
              wrap="wrap"
            >
              <MotionBox variants={itemVariants}>
                <LevelCard
                  number="1"
                  title="Romance"
                  description="Reignite the spark with questions about your relationship's romantic side."
                  color="pink"
                  icon={FaHeart}
                  onClick={handleLevelOneClick}
                  isDisabled={!isReset}
                />
              </MotionBox>
              
              <MotionBox variants={itemVariants}>
                <LevelCard
                  number="2"
                  title="Exploration"
                  description="Journey deeper into your connection with questions about desires and dreams."
                  color="purple"
                  icon={FaCompass}
                  onClick={handleLevelTwoClick}
                  isDisabled={!isReset}
                />
              </MotionBox>
              
              <MotionBox variants={itemVariants}>
                <LevelCard
                  number="3"
                  title="Stability"
                  description="Strengthen your foundation with questions about long-term goals and values."
                  color="blue"
                  icon={FaAnchor}
                  onClick={handleLevelThreeClick}
                  isDisabled={!isReset}
                />
              </MotionBox>
            </Flex>
          </MotionBox>
          
          {/* Reset button */}
          {!isReset && (
            <ScaleFade initialScale={0.8} in={!isReset}>
              <Button
                rounded={"full"}
                size="lg"
                px={8}
                fontWeight="bold"
                variant="outline"
                colorScheme="pink"
                leftIcon={<Icon as={FaRedo} />}
                onClick={handleReset}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "md"
                }}
                transition="all 0.3s"
              >
                Start Over
              </Button>
            </ScaleFade>
          )}

          {/* Flashcards section */}
          <Box w="full" maxW="5xl">
            {level && (
              <Box mb={8}>
                <Divider mb={8} />
                <Flex align="center" justify="space-between" mb={6}>
                  <Heading size="lg">
                    <Text as="span" color={
                      level === "Couples One" ? "pink.500" : 
                      level === "Couples Two" ? "purple.500" : "blue.500"
                    }>
                      {level.replace("Couples ", "Level ")}
                    </Text>{" "}
                    Questions
                  </Heading>
                  <Badge 
                    colorScheme={
                      level === "Couples One" ? "pink" : 
                      level === "Couples Two" ? "purple" : "blue"
                    }
                    fontSize="md"
                    px={3}
                    py={1}
                    borderRadius="full"
                  >
                    Active
                  </Badge>
                </Flex>
              </Box>
            )}
            
            <SlideFade in={isOpen} offsetY="40px">
              <Box 
                borderRadius="xl" 
                overflow="hidden"
                boxShadow="xl"
                bg={useColorModeValue("white", "gray.800")}
              >
                <Flashcards level={level} />
              </Box>
            </SlideFade>
          </Box>
        </VStack>
      </Container>
      
      <Footer />
    </Box>
  );
}