import "./css/App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import React from "react";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Start from "./Start";
import Couple from "./Couple";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/start" element={<Start />}></Route>
          <Route path="/couples" element={<Couple />}></Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;