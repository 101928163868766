import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Link,
  Flex,
  useColorModeValue,
  Icon,
  VStack,
  HStack,
  Badge,
  Circle,
  Divider
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FaArrowRight, FaFire, FaStar } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

// 3D rotating particles component
const ParticleField = () => {
  const [particles, setParticles] = useState([]);
  const count = 50;
  
  useEffect(() => {
    const newParticles = Array.from({ length: count }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 10 + 2,
      color: [
        'orange.300', 'orange.400', 'orange.500', 
        'red.300', 'yellow.300', 'pink.300'
      ][Math.floor(Math.random() * 6)],
      duration: Math.random() * 20 + 10,
      delay: Math.random() * 5
    }));
    setParticles(newParticles);
  }, []);

  return (
    <Box 
      position="absolute" 
      width="full" 
      height="full" 
      overflow="hidden"
      opacity={0.8}
      zIndex={0}
    >
      {particles.map(particle => (
        <MotionBox
          key={particle.id}
          position="absolute"
          left={`${particle.x}%`}
          top={`${particle.y}%`}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ 
            scale: [0, 1, 0.8, 1, 0],
            opacity: [0, 0.8, 1, 0.8, 0],
            rotate: [0, 180, 360]
          }}
          transition={{ 
            duration: particle.duration,
            repeat: Infinity,
            delay: particle.delay,
            ease: "easeInOut"
          }}
        >
          <Circle size={`${particle.size}px`} bg={particle.color} />
        </MotionBox>
      ))}
    </Box>
  );
};

// Animated headline letter components
const AnimatedLetter = ({ children, index }) => {
  return (
    <MotionBox
      as="span"
      display="inline-block"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.6, 
        delay: index * 0.05,
        ease: "easeOut"
      }}
    >
      {children}
    </MotionBox>
  );
};

export default function LegendaryLandingPage() {
  const bgGradient = useColorModeValue(
    'linear(to-br, purple.900, black)',
    'linear(to-br, purple.900, black)'
  );
  
  const headingColor = 'white';
  const accentColor = 'orange.400';
  const textColor = 'gray.200';
  
  const headline = "Let's get to know each other";
  const headlineArray = headline.split('');

  return (
    <Box
      bgGradient={bgGradient}
      minH="100vh"
      position="relative"
      overflow="hidden"
      py={20}
    >
      {/* Animated background particles */}
      <ParticleField />
      
      {/* Main content */}
      <Container maxW={'7xl'} position="relative" zIndex={2}>
        <Stack
          spacing={{ base: 12, md: 16 }}
          align={'center'}
          justify={'center'}
          pt={{ base: 10, md: 28 }}
          pb={{ base: 10, md: 20 }}
        >
          {/* Badge */}
          <MotionFlex
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Badge
              px={3}
              py={1}
              bg={useColorModeValue('orange.300', 'orange.300')}
              color="black"
              fontWeight="700"
              fontSize="md"
              textTransform="uppercase"
              borderRadius="full"
              letterSpacing="wider"
              boxShadow="xl"
            >
              <HStack spacing={1}>
                <Icon as={FaFire} />
                <Text>Create Meaningful Connections</Text>
              </HStack>
            </Badge>
          </MotionFlex>
          
          {/* Main heading with letter-by-letter animation */}
          <Box textAlign="center">
            <Heading
              fontWeight={800}
              fontSize={{ base: '4xl', sm: '5xl', md: '6xl', lg: '7xl' }}
              lineHeight={'100%'}
              letterSpacing="tight"
              color={headingColor}
              mb={6}
            >
              {headlineArray.map((letter, index) => (
                <AnimatedLetter key={index} index={index}>
                  {letter === ' ' ? '\u00A0' : letter}
                </AnimatedLetter>
              ))}
            </Heading>
            
            <MotionBox
              initial={{ width: 0 }}
              animate={{ width: "100px" }}
              transition={{ duration: 1, delay: 1.2 }}
              height="4px"
              bg="orange.400"
              mx="auto"
              mb={8}
            />
            
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              <Text 
                color={textColor} 
                fontSize={{ base: 'lg', md: 'xl' }}
                maxW="2xl"
                mx="auto"
                lineHeight="tall"
              >
                Those late nights shouldn't be wasted. 
                Whether you are a little tipsy or just want to pass some time,
                you should get to know the other and make friendships or relationships forever.
              </Text>
            </MotionBox>
          </Box>
          
          {/* CTA buttons with hover effects */}
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1 }}
          >
            <Stack 
              spacing={{ base: 4, sm: 6 }} 
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                as={Link}
                href='/start'
                size={'lg'}
                height="60px"
                px={8}
                fontSize="lg"
                fontWeight={'bold'}
                bg={accentColor}
                color="white"
                _hover={{ 
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 25px -5px rgba(251, 146, 60, 0.7)',
                  bg: 'orange.500'
                }}
                rightIcon={<Icon as={FaArrowRight} ml={2} />}
                borderRadius="xl"
                transition="all 0.3s ease"
              >
                Get started
              </Button>
              <Button
                size={'lg'}
                height="60px"
                px={8}
                fontSize="lg"
                fontWeight={'bold'}
                variant="outline"
                color="white"
                borderWidth={2}
                _hover={{ 
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 25px -5px rgba(255, 255, 255, 0.3)',
                  borderColor: 'white'
                }}
                borderRadius="xl"
                transition="all 0.3s ease"
              >
                Learn more
              </Button>
            </Stack>
          </MotionBox>
          
          {/* Floating 3D element */}
          <MotionBox
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              delay: 1.2,
              duration: 0.8,
              type: "spring", 
              stiffness: 100 
            }}
            mt={{ base: 8, md: 12 }}
            position="relative"
          >
            <Flex
              position="relative"
              justify="center"
              align="center"
              w={{ base: '280px', md: '400px' }}
              h={{ base: '280px', md: '400px' }}
            >
              {/* Glowing orb */}
              <MotionBox
                position="absolute"
                width="full"
                height="full"
                borderRadius="full"
                bgGradient="radial(orange.400, transparent 70%)"
                opacity={0.6}
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.4, 0.7, 0.4]
                }}
                transition={{ 
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              
              {/* Animated stars */}
              {[...Array(5)].map((_, i) => (
                <MotionBox
                  key={i}
                  position="absolute"
                  animate={{ 
                    rotate: 360,
                    scale: [1, 1.2, 0.9, 1],
                  }}
                  transition={{ 
                    duration: 10 + i * 2,
                    repeat: Infinity,
                    ease: "linear"
                  }}
                  top={`${20 + i * 15}%`}
                  left={`${15 + i * 15}%`}
                  color="yellow.300"
                  fontSize={20 + i * 4}
                >
                  <Icon as={FaStar} />
                </MotionBox>
              ))}
              
              {/* Central element */}
              <VStack
                spacing={6}
                bg="rgba(0,0,0,0.6)"
                backdropFilter="blur(10px)"
                borderWidth={2}
                borderColor="orange.400"
                borderRadius="3xl"
                p={6}
                boxShadow="0 0 40px rgba(251, 146, 60, 0.4)"
                textAlign="center"
                animate={{ 
                  y: [0, -15, 0],
                  rotate: [0, 5, 0, -5, 0]
                }}
                transition={{ 
                  duration: 10,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <Circle 
                  size="80px" 
                  bg="orange.400"
                  color="white"
                  fontSize="40px"
                >
                  <Icon as={FaFire} />
                </Circle>
                <Text fontWeight="bold" color="white" fontSize="xl">Connect Now</Text>
              </VStack>
            </Flex>
          </MotionBox>
        </Stack>
      </Container>
    </Box>
  );
}