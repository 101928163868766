import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Textarea,
  VStack,
  Text,
  useColorModeValue,
  Container,
  SimpleGrid,
  Icon,
  HStack,
  Circle,
  Divider,
  useToast
} from "@chakra-ui/react";
import { BsPerson, BsEnvelope, BsSend, BsArrowRight } from "react-icons/bs";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaComment, FaHeart, FaLightbulb } from "react-icons/fa";
import { useForm, ValidationError } from "@formspree/react";
import ContactDone from './components/ContactDone';
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);

// Contact feature card component - moved outside main component to avoid conditional hook calls
const FeatureCard = ({ icon, title, description, color }) => {
  const cardBg = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue(`${color}.500`, `${color}.200`);
  const cardBgHover = useColorModeValue(`${color}.50`, `${color}.900`);
  const textColor = useColorModeValue("gray.600", "gray.400");
  
  return (
    <MotionBox
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      bg={cardBg}
      p={6}
      borderRadius="lg"
      boxShadow="md"
      textAlign="center"
    >
      <Circle
        size="60px"
        bg={cardBgHover}
        color={cardColor}
        mb={4}
        mx="auto"
      >
        <Icon as={icon} fontSize="xl" />
      </Circle>
      <Text fontWeight="bold" fontSize="lg" mb={2}>
        {title}
      </Text>
      <Text color={textColor} fontSize="sm">
        {description}
      </Text>
    </MotionBox>
  );
};

// Input field wrapper component
const FormField = ({ icon, children, isRequired }) => {
  return (
    <FormControl isRequired={isRequired} position="relative">
      <InputGroup>
        <InputLeftElement color="orange.400">
          <Icon as={icon} />
        </InputLeftElement>
        {children}
      </InputGroup>
    </FormControl>
  );
};

export default function Contact() {
  // All hooks at the top level - before any conditional returns
  const [state, handleSubmit] = useForm("mvonpjpn");
  const [isVisible, setIsVisible] = useState(false);
  const toast = useToast();
  
  // All color mode values defined at the top level
  const bgGradient = useColorModeValue(
    "linear(to-br, gray.50, white, purple.50)",
    "linear(to-br, gray.900, purple.900)"
  );
  const formBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBg = useColorModeValue("orange.50", "gray.800");
  const inputBg = useColorModeValue("gray.50", "gray.700");
  const inputBorder = useColorModeValue("gray.200", "gray.600");
  const grayTextColor = useColorModeValue("gray.600", "gray.300");
  const circleBg = useColorModeValue("gray.100", "gray.700");
  const circleColor = useColorModeValue("gray.600", "gray.400");
  
  useEffect(() => {
    setIsVisible(true);
  }, []);
  
  // Show success toast when form is submitted
  useEffect(() => {
    if (state.succeeded) {
      toast({
        title: "Message sent!",
        description: "We'll get back to you as soon as possible.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [state.succeeded, toast]);
  
  // Animation variants
  const fadeUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };
  
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } }
  };
  
  // Return success page after hooks declarations
  if (state.succeeded) {
    return <ContactDone />;
  }

  return (
    <Box bgGradient={bgGradient} minH="100vh">
      <Navbar />
      
      <Container maxW="7xl" pt={{ base: 28, md: 36 }} pb={20}>
        <MotionBox
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeUp}
          textAlign="center"
          mb={16}
        >
          <Text 
            color="orange.400" 
            fontWeight="bold" 
            textTransform="uppercase" 
            letterSpacing="wide"
            mb={3}
          >
            Get In Touch
          </Text>
          <Heading
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            mb={6}
          >
            We'd Love To Hear From You
          </Heading>
          <Text 
            fontSize={{ base: "md", md: "lg" }} 
            color={grayTextColor}
            maxW="2xl"
            mx="auto"
          >
            Have ideas, suggestions, or questions? Send us a message and we'll get back to you as soon as possible.
          </Text>
        </MotionBox>
        
        {/* Feature cards section */}
        <MotionFlex
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={staggerContainer}
          mb={16}
        >
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} width="full">
            <FeatureCard 
              icon={FaComment}
              title="Conversation Ideas"
              description="Share your ideas for new questions or topics to add to our conversation cards."
              color="blue"
            />
            <FeatureCard 
              icon={FaHeart}
              title="Share Your Story"
              description="Tell us how CoupleFriends has helped deepen your relationships."
              color="red"
            />
            <FeatureCard 
              icon={FaLightbulb}
              title="Feature Requests"
              description="Suggest new features or improvements to make the app even better."
              color="yellow"
            />
          </SimpleGrid>
        </MotionFlex>
        
        {/* Contact form section */}
        <MotionBox
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeIn}
        >
          <Flex 
            direction={{ base: "column", lg: "row" }}
            bg={formBg}
            borderRadius="xl"
            overflow="hidden"
            boxShadow="xl"
          >
            {/* Left side - Contact info */}
            <Box 
              bg={headerBg}
              p={{ base: 8, md: 12 }}
              width={{ base: "full", lg: "350px" }}
            >
              <VStack align="start" spacing={10}>
                <Box>
                  <Heading size="lg" mb={6} color={textColor}>
                    Contact Information
                  </Heading>
                  <Text color={grayTextColor} mb={8}>
                    Reach out to us through any of these channels or use the form.
                  </Text>
                </Box>
                
                <VStack align="start" spacing={6}>
                  <HStack>
                    <Circle
                      size="40px"
                      bg="orange.400"
                      color="white"
                    >
                      <Icon as={FaEnvelope} />
                    </Circle>
                    <Box>
                      <Text fontWeight="medium">Email</Text>
                      <Text color={grayTextColor}>
                        contact@couplefriends.com
                      </Text>
                    </Box>
                  </HStack>
                  
                  <HStack>
                    <Circle
                      size="40px"
                      bg="orange.400"
                      color="white"
                    >
                      <Icon as={FaPhoneAlt} />
                    </Circle>
                    <Box>
                      <Text fontWeight="medium">Phone</Text>
                      <Text color={grayTextColor}>
                        +1 (555) 123-4567
                      </Text>
                    </Box>
                  </HStack>
                  
                  <HStack>
                    <Circle
                      size="40px"
                      bg="orange.400"
                      color="white"
                    >
                      <Icon as={FaMapMarkerAlt} />
                    </Circle>
                    <Box>
                      <Text fontWeight="medium">Location</Text>
                      <Text color={grayTextColor}>
                        San Francisco, CA
                      </Text>
                    </Box>
                  </HStack>
                </VStack>
                
                <Divider borderColor={inputBorder} />
                
                <Box>
                  <Text fontWeight="medium" mb={2}>Follow Us</Text>
                  <HStack spacing={4}>
                    <Circle
                      as="button"
                      size="36px"
                      bg={circleBg}
                      color={circleColor}
                      _hover={{ 
                        bg: "orange.400", 
                        color: "white",
                        transform: "translateY(-2px)" 
                      }}
                      transition="all 0.3s"
                    >
                      <Icon as={BsEnvelope} />
                    </Circle>
                    <Circle
                      as="button"
                      size="36px"
                      bg={circleBg}
                      color={circleColor}
                      _hover={{ 
                        bg: "orange.400", 
                        color: "white",
                        transform: "translateY(-2px)" 
                      }}
                      transition="all 0.3s"
                    >
                      <Icon as={BsEnvelope} />
                    </Circle>
                    <Circle
                      as="button"
                      size="36px"
                      bg={circleBg}
                      color={circleColor}
                      _hover={{ 
                        bg: "orange.400", 
                        color: "white",
                        transform: "translateY(-2px)" 
                      }}
                      transition="all 0.3s"
                    >
                      <Icon as={BsEnvelope} />
                    </Circle>
                  </HStack>
                </Box>
              </VStack>
            </Box>
            
            {/* Right side - Form */}
            <Box 
              p={{ base: 8, md: 12 }}
              width={{ base: "full", lg: "calc(100% - 350px)" }}
            >
              <form onSubmit={handleSubmit}>
                <VStack spacing={6} align="stretch">
                  <Heading size="lg" mb={2} color={textColor}>
                    Send Us A Message
                  </Heading>
                  <Text color={grayTextColor} mb={4}>
                    We'll get back to you as soon as possible.
                  </Text>
                  
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <FormField icon={BsPerson} isRequired={true}>
                      <Input 
                        name="name"
                        placeholder="Your Name"
                        size="lg"
                        bg={inputBg}
                        borderColor={inputBorder}
                        pl={10}
                        _focus={{
                          borderColor: "orange.400",
                          boxShadow: "0 0 0 1px var(--chakra-colors-orange-400)"
                        }}
                        _hover={{
                          borderColor: "orange.300"
                        }}
                      />
                      <ValidationError prefix="Name" field="name" errors={state.errors} />
                    </FormField>
                    
                    <FormField icon={FaEnvelope} isRequired={true}>
                      <Input 
                        name="email"
                        type="email"
                        placeholder="Your Email"
                        size="lg"
                        bg={inputBg}
                        borderColor={inputBorder}
                        pl={10}
                        _focus={{
                          borderColor: "orange.400",
                          boxShadow: "0 0 0 1px var(--chakra-colors-orange-400)"
                        }}
                        _hover={{
                          borderColor: "orange.300"
                        }}
                      />
                      <ValidationError prefix="Email" field="email" errors={state.errors} />
                    </FormField>
                  </SimpleGrid>
                  
                  <FormControl isRequired mt={2}>
                    <Textarea 
                      name="message"
                      placeholder="Your Message"
                      rows={8}
                      size="lg"
                      bg={inputBg}
                      borderColor={inputBorder}
                      _focus={{
                        borderColor: "orange.400",
                        boxShadow: "0 0 0 1px var(--chakra-colors-orange-400)"
                      }}
                      _hover={{
                        borderColor: "orange.300"
                      }}
                    />
                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                  </FormControl>
                  
                  <MotionBox
                    mt={4}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Button
                      type="submit"
                      disabled={state.submitting}
                      size="lg"
                      bg="orange.400"
                      color="white"
                      _hover={{ bg: "orange.500" }}
                      px={8}
                      h="56px"
                      fontSize="md"
                      fontWeight="bold"
                      leftIcon={<BsSend />}
                      rightIcon={<BsArrowRight />}
                      isFullWidth
                    >
                      {state.submitting ? "Sending..." : "Send Message"}
                    </Button>
                  </MotionBox>
                </VStack>
              </form>
            </Box>
          </Flex>
        </MotionBox>
      </Container>
      
      <Footer />
    </Box>
  );
}