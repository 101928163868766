import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Divider,
  Link,
  Flex,
  VStack,
  HStack,
  Icon,
  Badge,
  useColorModeValue,
  Avatar,
  Button,
  SimpleGrid
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaHeart, FaLightbulb, FaComments, FaGithub, FaStar, FaTwitter, FaInstagram } from 'react-icons/fa';

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);

const ValueCard = ({ icon, title, description }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  
  return (
    <MotionBox
      bg={cardBg}
      p={5}
      borderRadius="xl"
      boxShadow="md"
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      height="full"
    >
      <VStack spacing={3} align="center">
        <Icon as={icon} fontSize="2xl" color="orange.400" />
        <Text fontWeight="bold" fontSize="md">{title}</Text>
        <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize="sm" textAlign="center">
          {description}
        </Text>
      </VStack>
    </MotionBox>
  );
};

export default function AboutMain() {
  const bgGradient = useColorModeValue(
    'linear(to-br, gray.50, white, purple.50)',
    'linear(to-br, gray.900, purple.900)'
  );
  
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({ 
      opacity: 1, 
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <Box 
      pt={{ base: 24, md: 28 }}
      pb={{ base: 24, md: 32 }}
      bgGradient={bgGradient}
      width="100%"
      overflow="hidden"
      position="relative"
    >
      <Container maxW="5xl" px={{ base: 4, md: 6 }}>
        <VStack spacing={{ base: 8, md: 12 }} align="center">
          {/* Hero Section */}
          <Stack 
            direction="column" 
            spacing={{ base: 4, md: 6 }} 
            align="center" 
            textAlign="center"
            as={motion.div}
            initial="hidden"
            animate="visible"
            variants={staggerContainer}
            width="100%"
          >
            <Badge
              px={3}
              py={1}
              colorScheme="orange"
              fontSize="sm"
              borderRadius="full"
              custom={0}
              as={motion.div}
              variants={fadeInUp}
            >
              Our Mission
            </Badge>
            
            <MotionHeading
              fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }}
              custom={1}
              variants={fadeInUp}
              lineHeight="shorter"
            >
              About{' '}
              <Text 
                as={'span'} 
                bgGradient="linear(to-r, orange.400, pink.400)"
                bgClip="text"
              >
                CoupleFriends
              </Text>
            </MotionHeading>
            
            <MotionText 
              color={useColorModeValue('gray.600', 'gray.300')} 
              fontSize={{ base: 'md', md: 'lg' }}
              maxW="3xl"
              custom={2}
              variants={fadeInUp}
            >
              CoupleFriends is a digital card game designed to break down the barriers 
              of casual friendships and foster deeper, more meaningful connections through 
              thoughtful conversation.
            </MotionText>
            
            {/* Decorative element */}
            <MotionBox
              height="4px"
              width="60px"
              bg="orange.400"
              custom={3}
              variants={fadeInUp}
            />
          </Stack>
          
          {/* Values section */}
          <Box width="full">
            <Heading 
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign="center"
              mb={{ base: 4, md: 6 }}
            >
              Our Values
            </Heading>
            
            <SimpleGrid 
              columns={{ base: 1, md: 3 }}
              spacing={{ base: 4, md: 6 }}
              width="full"
              as={motion.div}
              initial="hidden"
              animate="visible"
              variants={staggerContainer}
            >
              <ValueCard 
                icon={FaComments} 
                title="Meaningful Connection" 
                description="We believe that deep conversations create bonds that last a lifetime."
              />
              
              <ValueCard 
                icon={FaHeart} 
                title="Authentic Relationships" 
                description="Real connections come from genuine, vulnerable conversations."
              />
              
              <ValueCard 
                icon={FaLightbulb} 
                title="Personal Growth" 
                description="Exploring new perspectives helps us grow as individuals and partners."
              />
            </SimpleGrid>
          </Box>
          
          <Divider my={{ base: 2, md: 3 }} />
          
          {/* Creator section */}
          <Box
            p={{ base: 5, md: 6 }}
            borderRadius="2xl"
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow="md"
            width="full"
            position="relative"
            overflow="hidden"
          >
            {/* Decorative background element - made smaller */}
            <Box
              position="absolute"
              top="-80px"
              right="-80px"
              width="200px"
              height="200px"
              borderRadius="full"
              bg="orange.100"
              opacity={0.3}
              zIndex={0}
            />
            
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="center"
              gap={{ base: 4, md: 6 }}
              position="relative"
              zIndex={1}
            >
              <Avatar 
                size={{ base: "lg", md: "xl" }}
                src="https://via.placeholder.com/150"
                name="Creator"
                bg="orange.400"
                boxShadow="md"
              />
              
              <VStack align={{ base: 'center', md: 'start' }} spacing={{ base: 2, md: 3 }} flex={1}>
                <Heading size={{ base: "md", md: "lg" }}>A Personal Note</Heading>
                <Text 
                  color={useColorModeValue('gray.600', 'gray.300')} 
                  fontSize={{ base: "sm", md: "md" }}
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  I created CoupleFriends during winter break (2023) as a passion project. What started as a fun idea
                  has grown into a platform that helps people connect on a deeper level. I'm constantly
                  improving the experience based on user feedback.
                </Text>
                
                <HStack spacing={3} mt={{ base: 1, md: 2 }}>
                  <Button leftIcon={<FaStar />} colorScheme="orange" size="sm">
                    Rate the App
                  </Button>
                  
                  <Link href="/contact" textDecoration="none">
                    <Button variant="outline" colorScheme="orange" size="sm">
                      Send Feedback
                    </Button>
                  </Link>
                </HStack>
              </VStack>
            </Flex>
          </Box>
          
          {/* Feature request section */}
          <Box 
            p={{ base: 4, md: 5 }}
            borderRadius="xl" 
            bg={useColorModeValue('orange.50', 'gray.700')}
            width="full"
            textAlign="center"
          >
            <VStack spacing={{ base: 2, md: 3 }}>
              <Heading size={{ base: "sm", md: "md" }}>Have Ideas to Make This Better?</Heading>
              <Text fontSize={{ base: "sm", md: "md" }}>
                I'm always looking for new features to add! I'd love to hear your suggestions.
              </Text>
              <Button
                as={Link}
                href="/contact"
                colorScheme="orange"
                _hover={{ textDecoration: 'none' }}
                size="sm"
                mt={1}
              >
                Share Your Ideas
              </Button>
            </VStack>
          </Box>
          
          {/* Social media links */}
          <HStack spacing={5} pt={2} pb={6}>
            <Link href="#" isExternal>
              <Icon as={FaTwitter} fontSize="lg" color="gray.500" />
            </Link>
            <Link href="#" isExternal>
              <Icon as={FaInstagram} fontSize="lg" color="gray.500" />
            </Link>
            <Link href="#" isExternal>
              <Icon as={FaGithub} fontSize="lg" color="gray.500" />
            </Link>
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
}